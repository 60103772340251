<template>
  <div class="homePage">
    <div class="left-menu">
      <img class="all_sys_title" src="@/assets/homeIcon/all_sys_title.png" alt="">
      <div>
        <div v-for="(item, i) in menuList" :key="item.type" class="menu-list">
          <h3 class="sbu-title">
            {{ getDictName(resSysTypeList, item.type) }}
          </h3>
          <div class="menu-box">
            <div
              v-for="(cl, index) in item.list"
              :key="index"
              class="item"
              @click="getSystemMenu(cl)"
              @mouseenter="moveIn(index, '1', i)"
              @mouseleave="removeMenu(index, '1', i)"
            >
              <img class="icon" :src="cl.active ? require(`@/assets/homeIcon/${cl.icon}_a.png`) : require(`@/assets/homeIcon/${cl.icon}.png`)" alt="">
              <div>
                <p class="resName">
                  {{ cl.resName }}
                </p>
                <p class="notes">
                  解决传统企业管理难题
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-info">
      <div class="corporate-box panel">
        <div class="info">
          <img :src="$localPicture+'logo/logo_small.png'" alt="">
          {{ userInfo.orgName }}
        </div>
        <div class="user">
          <div class="add-user" @click="skip('/system/user')">
            <img src="@/assets/homeIcon/add_user.png" alt="">
            添加成员
          </div>
          <div @click="skip('/system/Role')">
            <img src="@/assets/homeIcon/role.png" alt="">
            新增角色
          </div>
        </div>
      </div>
      <div class="user-info-box panel">
        <h3 class="sbu-title">
          使用人员信息
        </h3>
        <div class="user-info">
          <div class="item">
            <span>{{ statisticsObj.userCount || 0 }}</span>
            <p>组织总人数</p>
          </div>
          <div class="item">
            <span>{{ statisticsObj.adminUserName||'admin' }}</span>
            <p>超级管理员</p>
          </div>
          <div class="item">
            <span>{{ statisticsObj.roleCount || 0 }}</span>
            <p>创建角色数</p>
          </div>
        </div>
      </div>
      <!-- 最近使用 -->
      <div class="recently-use-box panel">
        <h3 class="sbu-title">
          最近使用
        </h3>
        <div class="use-menu">
          <div v-for="(item, index) in latestUsageMenuList" :key="item.resCode" @click="getSystemMenu(item)" @mouseenter="moveIn(index, '2')" @mouseleave="removeMenu(index, '2')">
            <img :src="item.active ? require(`@/assets/homeIcon/${item.icon}_a.png`) : require(`@/assets/homeIcon/${item.icon}.png`)" alt="">
            {{ item.resName }}
          </div>
        </div>
      </div>
      <img class="shopping-mall" src="@/assets/homeIcon/mall.png" alt="" @click="shoppingMall">
    </div>
  </div>
</template>

<script>
import { baseURL } from '@/systemConfiguration/index'
import { getAllPermission } from '@/api/systemManagement'
import { getUserAdminRoleCount } from '@/api/login'
import { getDictName } from '@/utils/util.js'
export default {
  data() {
    return {
      getDictName,
      allmenu: [],
      userInfo: {},
      allmenuArr: [],
      statisticsObj: {},
      resSysTypeList: this.$store.getters.getDictionaryItem('RES_SYS_TYPE'),
      menuList: [], // 首页菜单
      latestUsageMenuList: this.$store.state.latestUsageMenuList // 最近使用系统
    }
  },
  mounted() {
    console.log(this.latestUsageMenuList)
    // 这个websocket的是判断有没有相同的登录的
    const baseUrl = baseURL.replace('https', 'wss').replace('http', 'ws')
    var ws = new WebSocket(
      `${baseUrl}/websocket/${localStorage.getItem('logintoken')}`,
      ['superchat']
    )
    // 获取连接状态
    ws.onmessage = () => {
      this.$alert('您的账号已经在其他设备上登录', '提示', {
        confirmButtonText: '确定',
        showClose: false,
        callback: () => {
          this.$router.push({ path: '/login' })
          // 完成通信后关闭WebSocket连接
          ws.close()
        }
      })
    }
    getAllPermission(
      res => {
        this.allmenuArr = [...res.data]
        if (this.allmenuArr.length > 0 && this.allmenuArr[0].children) { this.allmenu = [...this.allmenuArr[0].children] }
        const typeArr = [];
        (this.$store.getters.getDictionaryItem('RES_SYS_TYPE') || []).map((item) => {
          typeArr.push({ type: item.dictId, list: [] })
        })
        const menuData = [...this.allmenuArr[0].children]
        menuData.map((item) => {
          typeArr.map((cl) => {
            if (cl.type === item.resSysType) {
              cl.list.push(item)
            }
          })
        })
        this.menuList = typeArr
      },
      () => {},
      '00'
    )
    getUserAdminRoleCount({ cmpRole: '00' }, (res) => {
      this.statisticsObj = { ...res.data }
    })
    this.userInfo = { ...JSON.parse(localStorage.getItem('userdata')) }
    if (this.userInfo.orgName) {
      this.userInfo.abbreviation = this.userInfo.orgName.slice(0, 4)
    }
  },
  methods: {
    skip(url) {
      const str = JSON.stringify(this.allmenu)
      if (str.indexOf(url) >= 0) {
        this.allmenu.forEach(item => {
          if (item.resCode === 'institutional_framework') {
            this.$store.commit('getSystemMenu', item)
          }
        })
        this.$router.push({ path: url })
        return
      }
      this.$message.error('暂无权限')
    },
    // 点击菜单
    getSystemMenu(obj) {
      // 没有儿子的时候就只能提示哦
      if (obj.children && obj.children.length === 0) {
        this.$message.error('正在建设中...')
        return
      }
      if (obj === 2 && this.allmenuArr.length > 0) {
        this.$store.commit('getSystemMenu', this.allmenuArr[0])
        this.$router.push({ path: '/' })
        return
      }
      if (obj.children && obj.children.length > 0) {
        this.$store.commit('getSystemMenu', obj)
        const params = JSON.parse(JSON.stringify(obj))
        delete params.active
        this.$store.commit('setLatestUsageMenuList', params)
        if (obj.children[0].resUrl) {
          this.$router.push({ path: obj.children[0].resUrl })
          return
        }
        if (obj.children[0].children && obj.children[0].children.length > 0) {
          this.$router.push({ path: obj.children[0].children[0].resUrl })
        }
        return
      }
    },
    moveIn(index, val, i) {
      if (val === '1') return this.$set(this.menuList[i].list[index], 'active', true)
      this.$set(this.latestUsageMenuList[index], 'active', true)
    },
    removeMenu(index, val, i) {
      if (val === '1') return this.$set(this.menuList[i].list[index], 'active', false)
      this.$set(this.latestUsageMenuList[index], 'active', false)
    },
    shoppingMall() {
      window.open(
        `${this.$projectUrl}login`,
        '_blank'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.homePage {
  padding: 20px;
  height: calc(100% - 40px);
  background: url("../../assets/img/main_bg.png");
  display: flex;
  justify-content: space-between;
}

.left-menu {
  background-color: #fff;
  flex: 1;
  margin-right: 16px;
  padding: 20px 28px;
  overflow-y: auto;
  .all_sys_title {
    width: 80px;
    height: 30px;
    margin-bottom: 32px;
  }
  .menu-list {
    margin-bottom: 16px;
    .sbu-title {
      margin-bottom: 16px;
    }
  }
  .menu-box {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 25%;
      // width: 312px;
      height: 78px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-left: 16px;
      box-sizing: border-box;
      .icon {
        width: 48px;
        height: 48px;
        margin-right: 12px;
      }
      .resName {
        color: #333;
        font-size: 16px;
        margin-bottom: 6px;
      }
      .notes {
        color: #666;
        font-size: 14px;
      }
      &:hover {
        background-color: #F9F9F9;
      }
    }
  }
}

.right-info {
  flex: 0 0 512px;

  .panel {
    padding: 24px;
    background-color: #fff;
    margin-bottom: 16px;
  }

  .corporate-box {

    .info{
      display: flex;
      align-items: center;
      color: #333333ff;
      font-size: 18px;
      img {
        width: 56px;
        height: 56px;
        margin-right: 8px;
      }
    }

    .user {
      display: flex;
      margin-left: 64px;
      margin-top: 20px;
      .add-user {
        margin-right: 16px;
      }
      > div {
        background-color: #F9F9F9;
        color: #333333ff;
        font-size: 14px;
        width: 192px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
    }
  }

  .user-info-box {
    padding-bottom: 30px;
    .user-info {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .item {
        text-align: center;
        span {
          color: #333333ff;
          font-size: 24px;
        }
        p {
          color: #666666ff;
          font-size: 14px;
        }
      }
    }
  }

  .recently-use-box {
    .use-menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 24px;
      > div {
        width: 224px;
        height: 40px;
        line-height: 40px;
        background: #f4f4f4ff;
        color: #333333ff;
        font-size: 14px;
        margin-bottom: 16px;
        padding-left: 18px;
        box-sizing: border-box;
        cursor: pointer;
        img {
          width: 18px;
          height: 18px;
          vertical-align: middle;
        }
      }
    }
  }

  .shopping-mall {
    width: 512px;
    height: 225px;
    cursor: pointer;
  }
}
.sbu-title {
  color: #000000ff;
  font-size: 16px;
  font-weight: 500;
}
</style>
